import axios from 'axios';
import store from '../store/index.js';

axios.interceptors.request.use((axiosConfig) => {
  const token = store.state['token'];

  if (token && !axiosConfig.excludeToken) {
    axiosConfig.headers['x-auth-token'] = token;
  }
  return axiosConfig;
});
