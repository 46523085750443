import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';

import './setup/axios-interceptors';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

require('@/assets/css/bundle.css');
require('@/vendors/slick/slick.css');
require('@/vendors/slick/slick-theme.css');
require('@/assets/css/app.css');
require('@/assets/css/gesdoc.css');

require('@/vendors/fontawesome/css/all.css');
require('@/vendors/fontawesome/css/brands.css');

import { library } from "@fortawesome/fontawesome-svg-core";
import { faHdd, faSearch, faPlus, faRedo, faSort, faStar as faStarSolid, faCheck, faArrowLeft, faFolderPlus, faInfoCircle, faPen, faDownload, faCopy } from "@fortawesome/free-solid-svg-icons";
import {
    faStar,
    faTrashAlt,
    faFolder,
    faObjectGroup
} from "@fortawesome/free-regular-svg-icons";

library.add(faHdd, faSearch, faPlus, faTrashAlt, faStar, faFolder, faRedo, faSort, faStarSolid, faObjectGroup, faCheck, faArrowLeft, faFolderPlus, faInfoCircle, faPen, faDownload, faCopy);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(store).use(router)
    .use(PrimeVue)
    // .use(PrimeVue, {
    //     locale: {
    //         dayNames: ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"],
    //         dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    //         dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
            // monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    //         monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
    //     }
    // })
    .use(ToastService)
    .mount('#app');
