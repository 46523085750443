<template>
  <loader />
  <div class="layout-wrapper" v-if="isAuthenticated">
    <the-header></the-header>

    <div class="content-wrapper">
      <div class="content-body">
        <div class="content">
          <router-view />
        </div>

        <the-footer />
      </div>
    </div>
  </div>
  <div class="layout-wrapper" v-else>
    <router-view />
  </div>
  <toast :autoZIndex="false" />
</template>

<script>
import Toast from 'primevue/toast';
import Loader from '@/components/Loader.vue';
import TheHeader from "@/components/header/TheHeader.vue";
import TheFooter from "@/components/footer/TheFooter.vue";
import { mapGetters } from 'vuex';

export default {
  components: { Toast, Loader, TheHeader, TheFooter  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    ...mapGetters('errors', ['hasConnection'])
  },
  async beforeCreate() {
    // await store.dispatch('initialise');
    document.title = 'Gesdoc Overdrive';
  },
  mounted() {
    if (this.isAuthenticated) {
      document.body.classList.remove("form-membership");
      document.body.classList.add("hidden-navigation");
    } else {
      document.body.classList.add("form-membership");
      document.body.classList.remove("hidden-navigation");
    }
  },
  watch: {
    isAuthenticated(newValue) {
      if (newValue) {
        document.body.classList.remove('form-membership');
        document.body.classList.add('hidden-navigation');
      } else {
        document.body.classList.add("form-membership");
        document.body.classList.remove("hidden-navigation");
      }
    },
    hasConnection(newValue) {
      // Monitor the connection with API.
      // If the connection drops send to a generic error page
      if (!newValue) {
        this.$router.push('/error');
      }
    },
  },
}
</script>

