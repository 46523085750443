<template>
  <Dialog header="Gesdoc Overdrive" v-model:visible="showDialog" >
		<div class="row">
			<div class="col">
				<img
					class="logo"
					src="@/assets/image/logo-gesdoc-overdrive.png"
					alt="image"
				/>
			</div>
			<div class="col">
				<p>
					Versão 0.7.0
				</p>

				<p>
					<a href="mailto:gesdoc@pc2.com.br">gesdoc@pc2.com.br</a>
				</p>

				<p>
					© 2023 (PC)² Inteligência Tecnológica<br />
					Todos os direitos reservados.
				</p>
			</div>
		</div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

export default {
  components: { Dialog },
	props: ['show'],
	computed: {
		showDialog: {
			get: function() {
				return this.show;
			},
			set: function() {
				this.$emit('close');
			}
		}
	}
}

</script>
