import {
  createStore
} from 'vuex'

import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';
import searchModule from './modules/search/index.js';
import nodesModule from './modules/nodes/index.js';
import errorsModule from './modules/errors/index.js';
import tagsModule from './modules/tags/index.js';

export default createStore({
  state() {
    return {
      gesdocVersion: '0.7.0',
      init         : false,
      nodeId       : 0,
      path         : [{
        nodeId: 0,
        name: ''
      }],
      api_endpoint: window.configs.API_ENDPOINT || process.env['VUE_APP_GESDOC_API'],
      apiEndpoint : window.configs.API_ENDPOINT || process.env['VUE_APP_GESDOC_API'],
      userId      : null,
      token       : null,
      email       : null,
      name        : null,
      avatar      : null,
      folderCount : 0,
      loading     : false,
      tags        : [],
      diskUsage   : 0,
      diskQuota   : 0,
      searchMode  : false,
      permissions : [],
      settings: [],
    }
  },
  mutations,
  actions,
  getters,
  modules: {
    search: searchModule,
    nodes: nodesModule,
    errors: errorsModule,
    tags: tagsModule
  }
})