export default {
  setNodes(state, nodes) {
    state.nodes = nodes;
  },
  deleteNode(state, nodeId) {
    state.nodes = state.nodes.filter((node) => node.nodeId !== nodeId);
  },

  setFiles(state, files) {
    state.files = files;
  },
  appendFile(state, file) {
    state.files.push(file);
  },

  setDirs(state, dirs) {
    state.dirs = dirs;
  },
  appendDir(state, payload) {
    const node = {
      nodeId: payload.nodeId,
      name  : payload.name,
      type  : 'd'
    };
    state.nodes.push(node);
  },

  addSelectedItem(state, nodeId) {
    const nodes = state.nodes.map(node => {
      if (node.nodeId === nodeId) {
        return {
          ...node,
          selected: true
        };
      }

      return node;
    });

    state.nodes = nodes;
  },
  removeSelectedItem(state, nodeId) {
    const nodes = state.nodes.map(node => {
      if (node.nodeId === nodeId) {
        return {
          ...node,
          selected: false
        };
      }

      return node;
    });

    state.nodes = nodes;
  },

  updateNode(state, payload) {
    const node = state.nodes.find((node) => node.nodeId === payload.nodeId);
    const pos = state.nodes.indexOf(node);

    if (payload.name) {
      node.name = payload.name;
    }
    
    if (typeof payload.reviewed !== 'undefined') {
      node.reviewed = payload.reviewed;
    }

    state.nodes[pos] = node;
  }
}