<template>
  <div></div>
  <!-- <div class="preloader" v-if="loading">
    <div class="preloader-icon"></div>
  </div> -->
</template>

<script>
import { mapGetters } from 'vuex';


export default {
  computed: {
    ...mapGetters(['loading'])
  }
};
</script>
