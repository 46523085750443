<template>
  <!-- begin::header -->
  <div class="header d-print-none">
    <div class="header-left">
      <div class="navigation-toggler">
        <a href="#" data-action="navigation-toggler">
          <i data-feather="menu"></i>
        </a>
      </div>
      <div class="header-logo">
        <router-link to="/files">
          <!-- gesdoc overdrive -->
          <img class="logo" src="@/assets/image/logo-gesdoc-overdrive.png" height="50" alt="logo">
          <span class="ms-3">
            gesdoc overdrive <strong>beta</strong>
          </span>
        </router-link>
      </div>
    </div>

    <div class="header-body">
      <div class="header-body-left">
        <!-- <div class="page-title">
                    <h4>File Manager</h4>
                </div> -->
      </div>
      <div class="header-body-right">
        <ul class="navbar-nav">
          <!-- begin::user menu -->
          <li class="nav-item dropdown">
            <a
              href="#"
              class="dropdown-toggle"
              title="User menu"
              data-bs-toggle="dropdown"
            >
              <!-- class="btn btn-outline-light dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false" -->
              <span class="me-2 d-sm-inline d-none">{{ userFirstName }}</span>
              <figure class="avatar avatar-sm">
                <img
                  src="@/assets/media/image/user/women_avatar1.jpg"
                  class="rounded-circle"
                  alt="avatar"
                />
              </figure>
            </a>
            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" to="/profile">Perfil</router-link></li>
              <li><router-link class="dropdown-item" to="/tags" v-if="hasPermission(permissionsConstants.TAGS_READ)">Tags</router-link></li>
              <li v-if="isAdmin"><router-link class="dropdown-item" to="/config">Configurações</router-link></li>
              <li><router-link class="dropdown-item" to="/statistics">Estatísticas</router-link></li>
              <li><hr class="dropdown-divider" /></li>
              <li><a href="javascript:;" class="dropdown-item" @click="displayAboutDialog">Sobre</a></li>
              <li><hr class="dropdown-divider" /></li>
              <li><a class="dropdown-item" href="#" @click="logout">Sair</a></li>
            </ul>
          </li>
          <!-- end::user menu -->
        </ul>

        <!-- begin::mobile header toggler -->
        <ul class="navbar-nav d-flex align-items-center">
          <li class="nav-item header-toggler">
            <a href="#" class="nav-link">
              <i data-feather="arrow-down"></i>
            </a>
          </li>
        </ul>
        <!-- end::mobile header toggler -->
      </div>
    </div>
  </div>

  <Dialog :show="showDialogAbout" @close="closeDialogAbout" />
  <!-- end::header -->
</template>

<script>
import { mapGetters } from 'vuex';
import Dialog from '../About.vue';
import permissionsConstants from '../../constants/permissions';

export default ({
  data() {
    return {
      showDialogAbout: false
    }
  },
  components: { Dialog },
  methods: {
    showAbout() {
      this.aboutVisibility = true;
    },
    closeAbout() {
      this.aboutVisibility = false;
    },
    logout: function() {
      if (confirm('Você quer sair do sistema?')) {
        this.$store.dispatch('logout');
        this.$router.push('/login');
      }
    },
    displayAboutDialog() {
      this.showDialogAbout = true;
    },
    closeDialogAbout() {
      this.showDialogAbout = false;
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'userEmail', 'userData', 'hasPermission']),
    userFirstName() {
      if (!this.userData.name) {
        return null;
      } else {
        const name = this.userData.name.split(' ').slice(0, 1);
        return name[0];
      }
    }
  },
  created() {
    this.permissionsConstants = permissionsConstants;
  },
})
</script>

<style scoped>
.header-logo span {
  font-family: 'Raleway', sans-serif;
  font-size: 1.5em;
  font-weight: 200;
}
.header-logo span span {
  font-weight: 500;
}

</style>
