export default {
  getNode: (state) => (nodeId) => {
    return state.nodes.find((node) => node.nodeId === nodeId);
  },
  getFiles(state) {
    return state.nodes.filter((node) => node.type === "f");
  },
  getDirs(state) {
    return state.nodes.filter((node) => node.type === "d");
  },
  noNodes(state) {
    return state.nodes.length === 0;
  },

  itemCount(state) {
    return state.nodes.length;
  },
  hasFiles(state, getters) {
    return getters.getFiles.length > 0;
  },

  hasSelectedItems(state) {
    return state.nodes.filter((node) => node.selected).length > 0;
    // return state.selectedNodes.length > 0;
  },
  selectedNodes(state) {
    return state.nodes.filter((node) => node.selected)
      .map(node => {
        return node.nodeId
      });
    // return state.nodes.filter((node) => node.selected);
  }
}