export default {
  /**
   * Check if a token is present
   * @param {state} state - Store state
   * @returns true if token is saved
   *          false if there's no token saved
   */
  isAuthenticated(state) {
    return !!state.userId;
  },
  api_endpoint(state) {
    return state.api_endpoint;
  },
  apiEndpoint(state) {
    return state.api_endpoint;
  },
  userId(state) {
    return state.userId;
  },
  userEmail(state) {
    return state.email
  },
  userData(state) {
    return {
      email: state.email,
      name: state.name,
      avatar: state.avatar
    }
  },
  isAdmin(state) {
    return state.admin;
  },
  token(state) {
    return state.token;
  },
  nodeId(state) {
    return state.nodeId;
  },
  itemCount(state) {
    return state.itemCount;
  },
  path(state) {
    return state.path;
  },
  pathName(state) {
    return state.pathName;
  },
  loading(state) {
    return state.loading;
  },
  tags(state) {
    return state.tags;
  },
  searchMode(state) {
    return state.searchMode;
  },
  hasPermission(state) {
    return (permission) => {
      return state.permissions.includes(permission);
    }
  },
  fetchSetting: (state) => (setting) => {
    const { value } = state.settings.find((el) => el.setting === setting);
    return value;
  }
}
