import axios from 'axios';
import nodesApi from '../../../api/nodes';

export default {
  setNodes(context, nodes) {
    context.commit('setNodes', nodes);
  },
  clearNodes(context) {
    context.commit('setNodes', []);
  },

  appendFile(context, file) {
    context.commit('appendFile', file);
  },


  clearDirs(context) {
    context.commit('setDirs', []);
  },
  setDirs(context, dirs) {
    context.commit('setDirs', dirs)
  },

  async newDir({ rootState, dispatch}, payload) {
    const { data } = await axios.post(`${rootState.apiEndpoint}/dir`, payload);

    dispatch('appendDir', { name: data.name, nodeId: data.nodeId });
  },
  appendDir(context, payload) {
    context.commit('appendDir', payload);
  },
  
  addSelectedItem(context, nodeId) {
    context.commit('addSelectedItem', nodeId);
  },
  removeSelectedItem(context, nodeId) {
    context.commit('removeSelectedItem', nodeId);
  },
  selectDeselectAllFiles({ getters, commit }) {
    const totalFiles = getters.getFiles.length;
    const totalSelected = getters.selectedNodes.length;

    // Determine if there are more files selected than not selected
    const ratio = totalSelected / totalFiles || 0;

    if (ratio == 1) {
      for (const node of getters.getFiles) {
        commit('removeSelectedItem', node.nodeId);
      }
    } else if (ratio == 0) {
      for (const node of getters.getFiles) {
        commit('addSelectedItem', node.nodeId);
      }
    } else if (ratio > 0.5) {
      for (const node of getters.getFiles) {
        commit('addSelectedItem', node.nodeId);
      }
    } else {
      for (const node of getters.getFiles) {
        commit('removeSelectedItem', node.nodeId);
      }
    }
  },

  updateNode(context, payload) {
    context.commit('updateNode', payload);
  },
  async deleteNode({ commit, rootState }, nodeId) {
    const path = `${rootState.apiEndpoint}/dir/${nodeId}`;

    await axios.delete(path);
    commit('deleteNode', nodeId);
  },

  async moveFile({ commit }, { nodeId, folderId }) {
    await nodesApi.moveFile(nodeId, folderId);
    commit('deleteNode', nodeId);
  },
}
