import axios from "axios";

export default {
  switchOrder(context) {
    const order = context.getters.order;

    if (order == 'asc') {
      context.commit('setOrder', 'desc');
    } else {
      context.commit('setOrder', 'asc');
    }
  },
  setSort(context, sort) {
    context.commit('setSort', sort);
  },
  setOrder(context, order) {
    context.commit('setOrder', order);
  },

  async advancedSearch({ commit, rootGetters }, payload) {
    const { data } = await axios.post(`${rootGetters.apiEndpoint}/search/advanced`, payload);
    commit('nodes/setNodes', data, {root: true});
  }
}