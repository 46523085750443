import axios from 'axios';
import store from '@/store';

export default {
  async getDirTree(nodeId) {
    const url = `${store.getters.apiEndpoint}/dir/tree/${nodeId}`;

    const { data } = await axios.get(url);
    return data;
  },

  async moveFile(nodeId, folderId) {
    const url = `${store.getters.apiEndpoint}/file/${nodeId}`;
    const body = {
      folderId
    };

    await axios.post(url, body);
  }
}
