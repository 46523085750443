import axios from 'axios';

export default {
  // async refreshTags() {
  async refreshTags(context) {
    const api_endpoint = context.rootGetters.api_endpoint;

    const response = await axios.get(`${api_endpoint}/tags`);

    context.commit('setTags', response.data);
  }
}