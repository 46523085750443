<template>
  <!-- begin::footer -->
  <footer class="content-footer">
    <div>
      © 2023 (PC)² inteligência tecnológica - Todos os direitos reservados
    </div>
    <!-- <div>
      <nav class="nav">
        <a href="https://themeforest.net/licenses/standard" class="nav-link"
          >Licenses</a
        >
        <a href="#" class="nav-link">Change Log</a>
        <a href="#" class="nav-link">Get Help</a>
      </nav>
    </div> -->
  </footer>
  <!-- end::footer -->
</template>