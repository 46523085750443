import router from '@/router'
import axios from 'axios';
import errors from '../constants/errors';

export default {
  async login({ commit, state, dispatch }, { email, password }) {
    const url = `${state.apiEndpoint}/auth`;
    const loginData = {
      email,
      password,
    };

    try {
      const { data } = await axios.post(url, loginData);

      // If login is programatic only, fail
      if (data.programaticAccessOnly) {
        throw new Error(errors.PROGRAMATICLOGIN);
      }

      commit('setToken', data.token);
      localStorage.setItem('token', data.token);

      await dispatch('fetchMe');

    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error(errors.UNAUTHORIZED);
      }
      
      if (error.message === errors.PROGRAMATICLOGIN) {
        throw new Error(errors.UNAUTHORIZED);
      }

      throw new Error(error.message);
    }
  },
  async tryLogin({ commit, dispatch }) {
    debugger;
    const token = localStorage.getItem('token');

    if (token) {
      commit('setToken', token);
      await dispatch('fetchMe');
    }
  },
  resetUserData({ commit }) {
    const data = {
      userId         : null,
      token          : null,
      email          : null,
      name           : null,
      avatar         : null,
      folderCount    : 0,
      loading        : '',
      tags           : []
    }
    commit('setUser', data);

    localStorage.removeItem('token');
    localStorage.removeItem('userId');
  },
  updateUserName(context, name) {
    context.commit('setUserName', name);
  },
  async fetchMe({state, commit, dispatch}) {
    try {
      const { data: res } = await axios.get(`${state.apiEndpoint}/me`);

      if (res.programaticAccessOnly) {
        throw new Error(errors.UNAUTHORIZED);
      }

      const data = {
        userId  : res.userId,
        email   : res.email,
        name    : res.name,
        admin   : res.admin,
        avatar  : res.avatar,
        inode   : 0
      }

      commit('setUser', data);

      commit('setUserPermissions', res.permissions);

      await dispatch('setDefaultSettings');
      commit('setUserSettings', res.settings);
    } catch {
      console.log('error');
    }
  },
  async setDefaultSettings({ commit }) {
    const defaultSettings = [
      {
        setting: 'viewMode',
        value: 'icon'
      }
    ];

    commit('setUserSettings', defaultSettings);
  },
  async setUserSetting({ commit }, setting) {
    commit('setUserSetting', setting);
  },
  async initialise({ commit, dispatch }) {
    commit('initialise');
    const token = localStorage.getItem('token');

    // If no token was found takes to /Login
    if (!token || token === undefined) {
      dispatch('resetUserData');
      router.push('/login');
      return;
    }
    
    commit('setToken', token);

    await dispatch('fetchMe');
  },
  async getDiskUsage({ commit, getters }) {
    const url = `${getters.apiEndpoint}/sys/du`;

    const { data } = await axios.get(url);

    commit('setDiskUsage', {
      diskUsage: data.diskUsage,
      diskQuota: data.diskQuota
    });
  },
  logout({dispatch}) {
    dispatch('resetUserData');
  },
  cd(context, nodeId) {
    context.commit('cd', nodeId);
  },
  setLoadingOn(context) {
    context.commit('loading', true);
  },
  setLoadingOff(context) {
    context.commit('loading', false);
  },
  loading(context, status) {
    context.commit('loading', status);
  },
  searchModeOn(context) {
    context.commit('searchMode', true);
  },
  searchModeOff(context) {
    context.commit('searchMode', false);
  },
  async pwd({ getters, commit }) {
    const { data } = await axios.get(`${getters.apiEndpoint}/dir/path/${getters.nodeId}`);
    commit('setPath', data);
  },
}