export default {
  initialise(state) {
    state.init = true;
  },
  setToken(state, token) {
    state.token = token;
  },
  setUser(state, payload) {
    state.userId = payload.userId;
    state.email = payload.email;
    state.name = payload.name;
    state.admin = payload.admin;
    state.avatar = payload.avatar;
    state.inode = payload.inode;
  },
  setUserPermissions(state, permissions) {
    state.permissions = permissions;
  },
  setUserName(state, name) {
    state.name = name;
  },
  setDiskUsage(state, payload) {
    state.diskUsage = payload.diskUsage;
    state.diskQuota = payload.diskQuota;
  },
  cd(state, nodeId) {
    state.nodeId = nodeId;
  },
  loading(state, status) {
    state.loading = status;
  },
  setTags(state, tags) {
    state.tags = tags;
  },
  searchMode(state, status) {
    state.searchMode = status;
  },
  setPath(state, path) {
    state.path = path;
  },
  setUserSettings(state, settings) {
    state.settings = settings;
  },
  setUserSetting(state, setting) {
    const settings = state.settings.filter((el) => el.setting !== setting.setting);
    state.settings = [...settings, setting];
  },
}