import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index.js';
import permissionsConstants from '../constants/permissions';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login/Login.vue')
  },
  {
    path: '/newPassword',
    name: 'NewPasswrd',
    component: () => import('@/views/Login/NewPassword.vue')
  },
  {
    path: '/error',
    component: () => import('@/views/Errors/Error.vue')
  },
  {
    path: '/',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/files',
    redirect: '/files/0'
  },
  {
    path: '/files/:nodeId',
    name: 'files',
    component: () => import('@/views/files/Structure.vue'),
    props: true,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/files/Structure.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/advancedSearch',
    name: 'advancedSearch',
    component: () => import('@/views/files/Structure.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    component: () => import('@/views/profile/Form.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tags',
    component: () => import('@/views/tags/List.vue'),
    meta: {
      requiresAuth: true,
      permission: permissionsConstants.TAGS_READ,
    }
  },
  {
    path: '/config',
    component: () => import('@/views/config/Config.vue'),
    meta: {
      requiresAdmin: true
    },
    children: [
      {
        path: 'users',
        component: () => import('@/views/config/users/List.vue')
      },
      {
        path: 'users/new',
        component: () => import('@/views/config/users/New.vue')
      },
      {
        path: 'users/edit/:userId',
        props: true,
        component: () => import('@/views/config/users/Edit.vue')
      }
    ]
  }, {
    path: '/statistics',
    component: () => import('@/views/statistics/Statistics.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, _, next) => {
  if (!store.state.init) {
    await store.dispatch('initialise');
  }

  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next('/login');
  } else if (to.meta.requiresAdmin && !store.getters.isAdmin) {
    next('/files');
  } else if (to.meta.permission && !store.getters.hasPermission(to.meta.permission)) {
    next('/files');
  } else {
    next();
  }
});

export default router
