export default {
  FILE_CREATE: 'file_create',
  FILE_READ: 'file_read',
  FILE_UPDATE: 'file_update',
  FILE_DELETE: 'file_delete',

  FOLDER_CREATE: 'folder_create',
  FOLDER_READ: 'folder_read',
  FOLDER_UPDATE: 'folder_update',
  FOLDER_DELETE: 'folder_delete',
  
  TAGS_CREATE: 'tags_create',
  TAGS_READ: 'tags_read',
  TAGS_UPDATE: 'tags_update',
  TAGS_DELETE: 'tags_delete',

  USER_MANAGEMENT: 'user_management',
}
